import React, { type VFC } from 'react';

import { LoadablePaint } from '@confluence/loadable';
import { PageSegmentLoadStart } from '@confluence/browser-metrics';
import { APP_NAVIGATION_METRIC } from '@confluence/browser-metrics/entry-points/app-navigation.metric';
import { ErrorBoundary, Attribution } from '@confluence/error-boundary';

const TopNavigationLoaderInner = LoadablePaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-TopNavigation" */ '../src/TopNavigation'))
			.TopNavigation,
});

export const TopNavigationLoader: VFC<{ initialCollapsedState: boolean }> = ({
	initialCollapsedState,
}) => {
	return (
		<ErrorBoundary
			attribution={Attribution.DISCO}
			attributes={{
				errorBoundaryId: 'TopNavigation',
			}}
		>
			<PageSegmentLoadStart metric={APP_NAVIGATION_METRIC} />
			<TopNavigationLoaderInner initialCollapsedState={initialCollapsedState} />
		</ErrorBoundary>
	);
};
