import { useCallback } from 'react';

import { useRouteActions, useRouteDataRef } from '@confluence/route-manager';
import {
	confluenceSessionStorageInstance as sessionStorage,
	keys,
} from '@confluence/storage-manager';

export const useSettingsBackButtonUtils = () => {
	const { push } = useRouteActions();
	const routeDataRef = useRouteDataRef();

	const saveCurrentRoute = useCallback(() => {
		const currentRoute = routeDataRef.current?.href;
		if (currentRoute) {
			sessionStorage.setItem(keys.NAV4_SETTINGS_BACK_BUTTON_URL, currentRoute);
		}
	}, [routeDataRef]);

	const goBack = useCallback(
		(defaultPath: string) => {
			const previousPath = sessionStorage.getItem(keys.NAV4_SETTINGS_BACK_BUTTON_URL);
			push(previousPath ?? defaultPath);
		},
		[push],
	);

	return {
		saveCurrentRoute,
		goBack,
	};
};
