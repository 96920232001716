import {
	GLOBAL_NAVIGATION_SEGMENT_KEY,
	createPageSegmentLoadMetric,
} from '@confluence/browser-metrics';

import { ssrConfig } from '../ssrConfig';

export const GLOBAL_NAVIGATION_METRIC = createPageSegmentLoadMetric({
	key: GLOBAL_NAVIGATION_SEGMENT_KEY,
	ssr: ssrConfig(),
});
