import { useCoordinationClient } from '@confluence/engagement-provider';

import { getChangeboardingMessageId } from './CoordinatedNav4ChangeboardingSpotlight';

export const useStopNav4Spotlight = (setActiveNav4Spotlight: (v: null) => void) => {
	const coordinationClient = useCoordinationClient();

	return () => {
		void coordinationClient.stop(getChangeboardingMessageId());
		setActiveNav4Spotlight(null);
	};
};
