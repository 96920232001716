import { useQuickstartState } from '@confluence/onboarding-quickstart';
import {
	confluenceLocalStorageInstance,
	PERSISTED_KEYS_ON_SERVER,
} from '@confluence/storage-manager';

const DEFAULT_SIDEBAR_WIDTH = 320;

export const useSideNavInitialState = () => {
	const { isPanelOpen, isDismissed } = useQuickstartState();
	const shouldForceExpand = isPanelOpen && !isDismissed;

	const persistedCollapseState =
		confluenceLocalStorageInstance.getItem(
			PERSISTED_KEYS_ON_SERVER.PERSISTED_LEFT_SIDEBAR_COLLAPSED,
		) ?? 'expanded';
	const persistedWidth = parseInt(
		confluenceLocalStorageInstance.getItem(
			PERSISTED_KEYS_ON_SERVER.PERSISTED_LEFT_SIDEBAR_WIDTH, //can return null
		) || DEFAULT_SIDEBAR_WIDTH,
		10,
	);

	const initialCollapsedState = shouldForceExpand ? 'expanded' : persistedCollapseState;

	const fittedSidebarWidth =
		persistedWidth > window.innerWidth ? DEFAULT_SIDEBAR_WIDTH : persistedWidth;

	return {
		initialCollapsedState,
		initialWidth: fittedSidebarWidth,
		shouldForceExpand,
	};
};
