import React, { useContext, useEffect } from 'react';

import { usePageLayoutResize } from '@atlaskit/page-layout';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { Attribution, ErrorBoundary } from '@confluence/error-boundary';
import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';
import { SpaceSettingsSideNavigation } from '@confluence/space-settings';
import { useSpaceKey } from '@confluence/space-utils';

const SpaceAdminNavigationInternal = () => {
	const spaceAlias = useSpaceKey();

	const { isLeftSidebarCollapsed } = usePageLayoutResize();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { location, matchRoute } = useContext(RoutesContext);
	const route = matchRoute(location?.pathname || '');
	useEffect(() => {
		createAnalyticsEvent({
			type: 'sendScreenEvent',
			data: {
				name: 'spaceAdminSideNavigation',
				attributes: {
					tab: route?.name || '',
					isVisible: !isLeftSidebarCollapsed,
				},
			},
		}).fire();
	}, [createAnalyticsEvent, isLeftSidebarCollapsed, route?.name]);

	return <SpaceSettingsSideNavigation spaceAlias={spaceAlias || ''} />;
};

export const SpaceAdminNavigation = (props) => (
	<ErrorBoundary attribution={Attribution.ADMIN_EXPERIENCE}>
		<SpaceAdminNavigationInternal {...props} />
	</ErrorBoundary>
);
