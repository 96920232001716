import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { IconButton } from '@atlaskit/button/new';
import MegaphoneIcon from '@atlaskit/icon/core/megaphone';
import FeedbackCollector, { FeedbackFlag } from '@atlaskit/feedback-collector';
import { FlagGroup } from '@atlaskit/flag';
import Tooltip from '@atlaskit/tooltip';

import { MenuButtonItem } from '@atlassian/navigation-system/side-nav/menu-button-item';

import { fg } from '@confluence/feature-gating';
import { useSessionData } from '@confluence/session-data';

const NAV_4_FEEDBACK_ENTRYPOINT_ID = '5002b4f2-9df8-4bb2-8798-56005ef6cbfc';
const NAV_4_FEEDBACK_GROUP_ID = 'confluence-nav-4-sidebar';

const context = [
	{
		id: 'feedback-group-id',
		value: NAV_4_FEEDBACK_GROUP_ID,
	},
];

export const Nav4FeedbackCollector = () => {
	const { userId, displayName, locale } = useSessionData();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [displayFlag, setDisplayFlag] = useState(false);
	const intl = useIntl();

	return (
		<>
			<Tooltip content={intl.formatMessage(i18n.shareFeedbackButtonText)}>
				{fg('confluence_nav4_global-invite') ? (
					<IconButton
						testId="nav4-feedback--icon"
						icon={MegaphoneIcon}
						onClick={() => {
							setIsOpen(true);
						}}
						label={intl.formatMessage(i18n.shareFeedbackButtonText)}
					/>
				) : (
					<MenuButtonItem
						testId="nav4-feedback--menu-button-item"
						elemBefore={<MegaphoneIcon label="" color="currentColor" />}
						onClick={() => {
							setIsOpen(true);
						}}
					>
						{intl.formatMessage(i18n.shareFeedbackButtonText)}
					</MenuButtonItem>
				)}
			</Tooltip>
			{isOpen && (
				<FeedbackCollector
					locale={locale}
					name={displayName ?? undefined}
					atlassianAccountId={userId ?? undefined}
					entrypointId={NAV_4_FEEDBACK_ENTRYPOINT_ID}
					onClose={() => {
						setIsOpen(false);
					}}
					onSubmit={() => {
						setDisplayFlag(true);
						setIsOpen(false);
					}}
					feedbackTitle={intl.formatMessage(i18n.feedbackCollectorTitle)}
					feedbackTitleDetails={intl.formatMessage(i18n.feedbackCollectorTitleDetails)}
					additionalFields={[
						{
							id: 'customfield_10047',
							value: context.map(({ id, value }) => `${id}: ${String(value)}`).join('\n'),
						},
					]}
				/>
			)}
			<FlagGroup onDismissed={() => setDisplayFlag(false)}>
				{displayFlag && <FeedbackFlag />}
			</FlagGroup>
		</>
	);
};

const i18n = defineMessages({
	shareFeedbackButtonText: {
		id: 'page-layout.nav4.feedback-collector.share-feedback-button.text',
		defaultMessage: 'Give feedback on the new navigation',
		description:
			'Text for button to open the feedback collector modal where user can share feedback',
	},
	feedbackCollectorTitle: {
		id: 'page-layout.nav4.feedback-collector.title',
		defaultMessage: 'Help us improve the new navigation',
		description: 'Title for the nav4 feedback collector modal',
	},
	feedbackCollectorTitleDetails: {
		id: 'page-layout.nav4.feedback-collector.title-details',
		defaultMessage: 'We’d love to hear about your experience with the new navigation.',
		description: 'Message for the sub-title for the nav4 feedback collector modal',
	},
});
